import React, { Component } from 'react';
import { Link } from '@curi/react-dom';

export default class Application extends Component {

  constructor(props) {

    super(props);

    this.clone = this.clone.bind(this);
    this.download = this.download.bind(this);
    this.openNotificationDialogue = this.openNotificationDialogue.bind(this);

  }

  render() {

    var Status;

    var Options = {};

    if(this.props.application.status_cd === 'E') {

      Status = 'Valmis'

      Options['id'] = 'Applied';

    }

    else if(this.props.application.status_cd === 'L' || this.props.application.status_cd === 'K') {

      Status = 'Pooleli';

      Options['id'] = 'Unfinished';

    }

    else if(this.props.application.status_cd === 'X') {

      Status = 'Tühistatud';

      Options['id'] = 'Nullified';

    }

    else {

      Status = 'Kinnitatud';

      Options['id'] = 'Accepted';

    }

    var applicant_role = '';

    if(this.props.application.applicant_role_cd === 'I') {

      applicant_role = 'Isiklikult';

    }

    else if(this.props.application.applicant_role_cd === 'E') {

      applicant_role = 'Esindaja';

    }

    else if(this.props.application.applicant_role_cd === 'P') {

      applicant_role = 'Pärija';

    }

    var background = {};

    if(this.props.application.message_sent === 'Y') {

      background['className'] = 'backgroundYellow';

    }

    else if(this.props.application.message_sent === 'M') {

      background['className'] = 'backgroundLightBlue';

    }

    return(

      <tr>
        <td {...Options} className='Width100'>
          {this.props.application.personal_cd === '' ? '' :
            <Link
              className='linkLikeText'
              name="ApplicantView"
              params={{ partyID: this.props.application.party_id }}>
              {this.props.application.personal_cd}
            </Link>}
        </td>
        <td {...Options} className='Width70'>{this.props.application.user_password}</td>
        <td {...Options} className='Width100'>{this.props.application.applicant_first_name} {this.props.application.applicant_family_name}</td>
        <td {...Options} className='Width100'>{this.props.application.user_password === '' ? '' : this.props.application.party_email}</td>
        <td {...Options} className='Width80'>
          <Link
            className='linkLikeText'
            name="EditApplication"
            params={{ applicationID: this.props.application.appl_id }}>
            {this.props.application.change_dtime || this.props.application.create_dtime}
          </Link>
        </td>
        <td {...Options} className='textCenter'>
          <Link
            className='linkLikeText'
            name="ApplicationView"
            params={{ applicationID: this.props.application.appl_id }}>
            {this.props.application.appl_id}
          </Link>
        </td>
        <td {...Options} className='Width80'>{this.props.application.file_id != null ? <u className='ButtonLikeLink' onClick={this.download}>{this.props.application.file_dtime}</u> : null}</td>
        <td {...Options} className='Width100'>{this.props.application.author_first_name} {this.props.application.author_family_name}</td>
        <td {...Options} className='Width80'>{Status}</td>
        <td {...Options} className='Width60'>{applicant_role}</td>
        <td {...Options} className='Width40' onClick={this.clone}><button type='button' id='TableButton'>Lisa</button></td>
        <td {...Options} className='Width60' onClick={this.openNotificationDialogue}><button type='button' id='TableButton' {...background}>Teavitus</button></td>
      </tr>

    );

  }

  clone() {

    this.props.cloneApplication(this.props.application.appl_id);

  }

  download() {

    this.props.downloadApplication(this.props.index);

  }

  openNotificationDialogue() {

    this.props.openNotificationDialogue(this.props.index);

  }

}