import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import EditParty from './EditParty';
import EditPartyApplication from './EditPartyApplication';
import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  getCookie,
  getErrorMessage,
  setCookie
} from './Utils';


export default class AdministrationTool extends Component {

  constructor(props) {

    super(props);

    this.catchError = this.catchError.bind(this);
    this.changeIsLoading = this.changeIsLoading.bind(this);
    this.ChangePartyApplicantName = this.ChangePartyApplicantName.bind(this);
    this.compareParty = this.compareParty.bind(this);
    this.DownloadApplication = this.DownloadApplication.bind(this);
    this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
    this.EndDownloadConnection = this.EndDownloadConnection.bind(this);
    this.EndPartyApplicantConnection = this.EndPartyApplicantConnection.bind(this);
    this.EndPartyApplicantConnectionError = this.EndPartyApplicantConnectionError.bind(this);
    this.endPartyConnection = this.endPartyConnection.bind(this);
    this.loadApplications = this.loadApplications.bind(this);
    this.loadParty = this.loadParty.bind(this);
    this.logOut = this.logOut.bind(this);
    this.OpenEditParty = this.OpenEditParty.bind(this);
    this.Return = this.Return.bind(this);
    this.RunSearch = this.RunSearch.bind(this);
    this.RunSearchNow = this.RunSearchNow.bind(this);
    this.Search = this.Search.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.ViewPartyApplicant = this.ViewPartyApplicant.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.config = {
      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: getCookie('AHFToken')},
    };
    this.message = [];
    this.state = {
      applications: [],
      EditParty: false,
      isLoading: false,
      party: {},
      PartyApplicant: null,
      showMessage: false
    };
    this.Searching = false;

  }

  render() {

    document.body.style.backgroundColor = '#222222';

    document.body.style.margin = '0';

    var Options = {};

        if(this.state.isLoading) {

            Options['disabled'] = 'disabled';

        }

    var ValidApplication = this.state.applications.slice(0, 0);

    if(this.PartyID != null) {

      for (var i = 0; i < this.state.applications.length; i ++) {

        if(this.state.applications[i].status_cd === 'C') {

          var Match = false;

          for(var j = 0; j < ValidApplication.length; j ++) {

            if(this.state.applications[i].applicant_role_cd === ValidApplication[j].applicant_role_cd) {

              if
                (this.state.applications[i].applicant_role_cd === 'I'
                ||
                  (this.state.applications[i].applicant_role_cd !== 'I'
                  && this.state.applications[i].author_party_id === ValidApplication[j].author_party_id)) {

                Match = true;

                if(this.state.applications[i].change_dtime > ValidApplication[j].change_dtime) {

                  ValidApplication[j] = this.state.applications[i];

                }

                break;

              }

            }

          }

          if(!Match) {

            ValidApplication.push(this.state.applications[i]);

          }

        }

      }

    }

    return(

      <div id='Page'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div id='Header'>
          <div id='Tabs' style={{'marginTop': '10px'}}>
            <Link
              className='unselectedTab'
              name="Applications">
              Laekunud avaldused
            </Link>
            <div id='SelectedTab'>Administreerimine<p></p></div>
            <Link
              className='unselectedTab'
              name="Archive">
              Arhiiv
            </Link>
            <Link
              className='unselectedTab'
              name="Reports">
              Raportid
            </Link>
            <Link
              className='unselectedTab'
              name="UploadFile">
              Failide üleslaadimine
            </Link>
          </div>
          <div id='Block'>
            <table className='MaxWidth'>
              <tbody>
                <tr>
                  <td className='Logo'>
                    <img src='/img/logo.gif' alt=''></img>
                  </td>
                  <td className='Center'>
                    <h2>Autorihüvitusfond: Reprograafiahüvitiste administreerimine.</h2>
                  </td>
                  <td className='TopButtons'>
                    <button {...Options} type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id='Footer'>
        </div>
        {<div id='Body'>
          <div id='Sidebar'>
            <div id='Search'>
              <div id='SearchInput'>
                <input
                  {...Options}
                  type='text'
                  size='9'
                  maxLength='20'
                  onChange={this.Search}>
                </input>
              </div>
              <div id='SearchIcon'><img src='/img/find.png' alt=''></img></div>
            </div>
            <div id='SearchList'>
              {this.state.PartyApplicant == null ? null :
                  this.state.PartyApplicant.map(function(applicant, i) {

                  var Style = {};

                  if(i % 2 === 0) {

                    Style = {'backgroundColor': 'white'};

                  }

                  else {

                    Style = {'backgroundColor': '#E7EEEE'};

                  }

                  return(

                    <div style={Style} className='SearchLine' key={i}>
                      <button {...Options} className='SearchButton' onClick={this.ViewPartyApplicant.bind(this, i)}>
                        <img src='/img/what.png' alt=''></img>
                        {applicant.legal_name == null ? applicant.first_name + ' ' + applicant.family_name : applicant.legal_name}
                      </button>
                    </div>

                  );

                }, this)}
            </div>
          </div>
          <div id='DataArea'>
            {this.state.party == null || this.PartyID == null || this.state.party.party_id !== this.PartyID ? null :
              <div>
                <div id='Title'>
                  <p></p>
                  Taotleja: {this.state.party.legal_name == null ? this.state.party.applicant_first_name + ' ' + this.state.party.applicant_family_name : this.state.party.legal_name}
                  <p></p>
                  <div className='SectionName'>Taotleja andmed</div>
                  {!this.state.EditParty ?
                    <table className='CurrentTable'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Esitaja eesnimi</th>
                          <th>Esitaja perenimi</th>
                          <th>Esitaja tüüp</th>
                          {this.state.party.legal_cd == null ? <th>Resident</th> : null}
                          {this.state.party.legal_cd == null ? <th>Isikukood</th> : null}
                          {this.state.party.legal_cd == null && this.state.party.individual_cd != null ? <th>FIE reg. nr.</th> : null}
                          {this.state.party.legal_cd != null ? <th>Jur. isiku nimi</th> : null}
                          {this.state.party.legal_cd != null ? <th>Jur. isiku reg. nr.</th> : null}
                          <th>Parool</th>
                          <th>Parooli e-post</th>
                          <th>Tänav, maja, korter</th>
                          <th>Linn</th>
                          <th>Postiindeks</th>
                          <th>E-posti aadress</th>
                          <th>Telefon</th>
                          <th>IBAN</th>
                          {this.state.party.iban != null && this.state.party.iban.substring(0, 2).toLowerCase() !== 'ee' ? <th>BIC</th> : null}
                          <th>Kommentaar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr onClick={this.OpenEditParty}>
                          <td>{this.state.party.party_id}</td>
                          <td>{this.state.party.applicant_first_name}</td>
                          <td>{this.state.party.applicant_family_name}</td>
                          <td>{this.state.party.legal_cd != null ? 'Jur. isik' : (this.state.party.individual_cd != null ? 'FIE' : 'Füüsiline isik')}</td>
                          {this.state.party.legal_cd == null ? <td>{this.state.party.resident_country_cd === 'EE' ? 'EE' : 'Muu'}</td> : null}
                          {this.state.party.legal_cd == null ? <td>{this.state.party.personal_cd}</td> : null}
                          {this.state.party.legal_cd == null && this.state.party.individual_cd != null ? <td>{this.state.party.individual_cd}</td> : null}
                          {this.state.party.legal_cd != null ? <td>{this.state.party.legal_name}</td> : null}
                          {this.state.party.legal_cd != null ? <td>{this.state.party.legal_cd}</td> : null}
                          <td>{this.state.party.password_}</td>
                          <td>{this.state.party.password_epost}</td>
                          <td>{this.state.party.address_line2}</td>
                          <td>{this.state.party.address_line1}</td>
                          <td>{this.state.party.zip_cd}</td>
                          <td>{this.state.party.email}</td>
                          <td>{this.state.party.phone1}</td>
                          <td>{this.state.party.iban}</td>
                          {this.state.party.iban != null && this.state.party.iban.substring(0, 2).toLowerCase() !== 'ee' ? <td>{this.state.party.BIC}</td> : null}
                          <td>{this.state.party.party_comment}</td>
                        </tr>
                      </tbody>
                    </table> :
                    <EditParty
                      APIURL={this.APIURL}
                      catchError={this.catchError}
                      ChangeConnecting={this.changeIsLoading}
                      Connecting={this.isLoading}
                      LoadParty={this.loadParty}
                      Party={this.state.party}
                      Return={this.Return}
                      RunSearch={this.RunSearchNow}
                      ShowAlert={this.showAlert}
                      Token={getCookie('AHFToken')}
                    />}
                  {ValidApplication.length > 0 ?
                    ValidApplication.map(function(application, i) {

                      return(

                        <div key={i} className='EditApplication'>
                          <div style={{'margin': '5px'}}>
                            <p></p>
                            Taotlus: {application.appl_id}
                              {application.file_id != null ? <img {...Options} onClick={this.DownloadApplication.bind(this, i)} className='ResizeImage' src='/img/attach.png' alt=''></img> : null}
                            <p></p>
                            <EditPartyApplication
                              APIURL={this.APIURL}
                              Application={application}
                              catchError={this.catchError}
                              ChangeConnecting={this.changeIsLoading}
                              ChangePartyApplicantName={this.ChangePartyApplicantName}
                              Connecting={this.isLoading}
                              LoadApplication={this.loadApplications}
                              ShowAlert={this.showAlert}
                              Token={getCookie('AHFToken')}
                            />
                          </div>
                        </div>

                      );

                    }, this) : null}
                </div>
              </div>}
          </div>
        </div>}
      </div>

    );

  }

  catchError(error) {

    this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  changeIsLoading(isLoading) {

    this.setState({ isLoading: isLoading });

  }

  ChangePartyApplicantName(Name) {

    var PartyApplicant = this.state.PartyApplicant.slice();

    PartyApplicant[this.Index].name = Name

    this.setState({PartyApplicant: PartyApplicant});

  }

  compareParty(a, b) {

    let aString = a.legal_name == null ? a.first_name + ' ' + a.family_name : a.legal_name;
    let bString = b.legal_name == null ? b.first_name + ' ' + b.family_name : b.legal_name;

    if(!aString.endsWith('- PA') && bString.endsWith('- PA')) {

      return -1;

    }

    else if(aString.endsWith('- PA') && !bString.endsWith('- PA')) {

      return 1;

    }

    else if(aString < bString) {

      return -1;

    }

    else if(aString > bString) {

      return 1;

    }

    else {

      return 0;

    }

  }

  DownloadApplication(i) {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'applications/' + this.state.applications[i].appl_id + '/' + this.state.applications[i].party_id + '/files/' + this.state.applications[i].file_id;

    axios.get(URL, this.config).then(this.EndDownloadConnection).catch(this.catchError);

  }

  endApplicationsConnection(response) {

    this.setState({
      applications: response.data.data,
      isLoading: false
    })

  }

  EndDownloadConnection(Response) {

    var byteCharacters = atob(Response.data.data.bl_file.replace(/\s/g, ""));

    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {

      byteNumbers[i] = byteCharacters.charCodeAt(i);

    }

    var byteArray = new Uint8Array(byteNumbers);

    var blob = new Blob([byteArray], {type: ''});

    if(window.navigator.msSaveOrOpenBlob) {

      window.navigator.msSaveBlob(blob, Response.data.data.file_name);

    }

    else {

      var a = document.createElement('a');

      document.body.appendChild(a);

      a.style = 'display: none';

      var url = window.URL.createObjectURL(blob);

      a.href = url;

      a.download = Response.data.data.file_name;

      a.click();

      window.URL.revokeObjectURL(url);

    }

    this.setState({ isLoading: false });

  }

  EndPartyApplicantConnection(Response) {

    this.setState({PartyApplicant: Response.data.data.sort(this.compareParty)});

    this.RunSearch();

  }

  EndPartyApplicantConnectionError(Response) {

    this.LastSearchValue = null;

    this.Searching = false;

    this.showAlert('Otsing ebaõnnestus!');

    this.cathcError(Response);

  }

  endPartyConnection(response) {

    this.setState({
      isLoading: false,
      party: response.data.data
    })

  }

  loadApplications(partyID) {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'applications?filtered=false&party_id=' + partyID;

    axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

  }

  loadParty(partyID) {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'parties/' + partyID;

    axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

  }

  logOut() {

    setCookie('AHFLevel', getCookie('AHFLevel'), -1);
    setCookie('AHFToken', getCookie('AHFToken'), -1);
    setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

  }

  OpenEditParty() {

    if(!this.isLoading) {

      this.setState({EditParty: true});

    }

  }

  Return() {

    this.setState({EditParty: false});

  }

  RunSearch() {

    if(this.CurrentSearchValue !== this.LastSearchValue) {

      this.RunSearchNow();

    }

    else {

      this.Searching = false;

    }

  }

  RunSearchNow() {

    this.LastSearchValue = this.CurrentSearchValue;

    var URL = this.APIURL + 'parties/applicants?search=' + this.LastSearchValue;

    axios.get(URL, this.config).then(this.EndPartyApplicantConnection).catch(this.EndPartyApplicantConnectionError);

  }

  Search(event) {

    this.CurrentSearchValue = event.target.value;

    if(!this.Searching) {

      this.Searching = true;

      this.RunSearch();

    }

  }

  setShowMessage(showMessage) {

    this.setState({showMessage: showMessage});

  }

  showAlert(message) {

    this.message.push([{
      value: message,
      align: 'center'
    }]);

    this.setState({ showMessage: true });

  }

  ViewPartyApplicant(Index) {

    this.Return();

    this.Index = Index;

    this.PartyID = this.state.PartyApplicant[Index].party_id;

    this.loadParty(this.state.PartyApplicant[Index].party_id);

    this.loadApplications(this.state.PartyApplicant[Index].party_id);

  }

}
