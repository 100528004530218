import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getErrorMessage,
	validateAuthorFamilyName,
	validateAuthorFirstName,
	validateBIC,
	validateCountryCode,
	validateEMail,
	validateIBAN,
	validateIndividualCode,
	validateLegalCode,
	validateLegalName,
	validatePersonalCode,
	validatePhone,
	validateSubmitterFamilyName,
	validateSubmitterFirstName,
	validateSubmitterType,
	validateZIP
} from './Utils';

export default class Register extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.EndSubmitConnection = this.EndSubmitConnection.bind(this);
		this.HandleSubmit = this.HandleSubmit.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.ToggleBIC = this.ToggleBIC.bind(this);
		this.ToggleEsitaja = this.ToggleEsitaja.bind(this);
		this.ToggleFocusAuthor = this.ToggleFocusAuthor.bind(this);
		this.ToggleIsiklikult = this.ToggleIsiklikult.bind(this);
		this.UpdateAutoriEesnimed = this.UpdateAutoriEesnimed.bind(this);
		this.UpdateAutoriPerenimi = this.UpdateAutoriPerenimi.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.Isiklikult = '';
		this.message = [];
		this.navigateToLogin = false;
		this.state = {
      AutoriEesnimed: null,
      AutoriPerenimi: null,
			Esitaja: 'NULL',
			BIC: false,
			FocusAuthor: false,
			isLoading: false,
			showMessage: false
		};

	}

	render() {

		document.body.style.backgroundColor = '#E7D074';

		return(

			<div className='mediumBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='mediumBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Kasutaja registreerimine</h2>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
						<form onSubmit={this.HandleSubmit.bind(this)}>
							<table className='MaxWidth'>
								<tbody>
									<tr>
										<td className='Subtitle' colSpan='2'>
											Avalduse esitaja andmed
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Avalduse esitaja eesnimi:
										</td>
										<td>
											<input
												autoFocus
                        name='Eesnimed'
												type='text'
												size='25'
												maxLength='50'
												onChange={this.UpdateAutoriEesnimed}>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Avalduse esitaja perekonnanimi:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Perenimi'
												maxLength='50'
												onChange={this.UpdateAutoriPerenimi}>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Esitaja tüüp:
										</td>
										<td>
											<select onChange={this.ToggleEsitaja}>
												<option value='NULL'>Vali esitaja tüüp</option>
												<option value='fuusiline_isik'>Füüsiline isik</option>
												<option value='fie'>FIE</option>
												<option value='jur_isik'>Jur. isik</option>
											</select>
										</td>
									</tr>
									{this.state.Esitaja === 'fie' ?
										<tr>
											<td></td>
											<td id='FieTooltip'>
												(FIE hüvitistelt ei arvestata tulumaksu)
											</td>
										</tr>
										: null}
									{this.state.Esitaja === 'fuusiline_isik' || this.state.Esitaja === 'fie' ?
										<tr>
											<td className='RequiredField'>
												Resident:
											</td>
											<td>
												<select name='Resident'>
													<option value='NULL'>Vali resident</option>
													<option value='EE'>EE</option>
													{this.state.Esitaja === 'fuusiline_isik' ? <option value='Mu'>Muu</option> : null}
												</select>
											</td>
										</tr>
										: null}
									{this.state.Esitaja === 'fuusiline_isik' || this.state.Esitaja === 'fie' ?
										<tr>
											<td className='RequiredField'>
												Isikukood:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='Isikukood'
													maxLength='20'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.Esitaja === 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Jur. isiku nimi:
											</td>
											<td>
												<input
													type='text'
													size='25'
													name='Nimi'
													maxLength='50'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.Esitaja === 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Jur. isiku reg. nr.:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='JurIsikuRegNr'
													maxLength='20'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.Esitaja === 'fie' ?
										<tr>
											<td className='RequiredField'>
												FIE reg. nr.:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='FIERegNr'
													maxLength='8'>
												</input>
											</td>
										</tr>
										: null}
									<tr>
										<td className='Subtitle' colSpan='2'>
											Aadress
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Tänav, maja, korter:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Aadress'
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Linn:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Linn'
												maxLength='30'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Postiindeks:
										</td>
										<td>
											<input
												type='text'
												size='12'
												name='Postiindeks'
												maxLength='5'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='Subtitle' colSpan='2'>
											E-posti aadressile saadetakse parool, millega pääsete avalduse koostamise lehtedele
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											E-posti aadress:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='EPost'
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Telefon:
										</td>
										<td>
											<input 
												type='text'
												size='25'
												name='Telefon'
												maxLength='20'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='Subtitle' colSpan='2'>
											Panga andmed
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											IBAN:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='IBAN'
												maxLength='50'
												onChange={this.ToggleBIC}>
											</input>
										</td>
									</tr>
									{this.state.BIC ?
										<tr>
											<td className='RequiredField'>
												BIC:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='BIC'
													maxLength='8'>
												</input>
											</td>
										</tr>
										: null}
									<tr>
										<td className='Subtitle' colSpan='2'>
											Autori nimi või pseudonüüm, mille all on teosed avaldatud
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Autori eesnimi (või -nimed):
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='AutoriEesnimed'
                        defaultValue={this.state.AutoriEesnimed}
												maxLength='50'
												onChange={this.ToggleFocusAuthor}>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Autori perekonnanimi:</td>
										<td>
											<input
												type='text'
												size='25'
                        defaultValue={this.state.AutoriPerenimi}
												name='AutoriPerenimi'
												maxLength='50'
												onChange={this.ToggleFocusAuthor}>
											</input>
										</td>
									</tr>
									{!this.state.FocusAuthor || this.state.Esitaja === 'jur_isik' ? null :
										<tr>
											<td className='RequiredField'>
												Esitan isiklikult:
											</td>
											<td>
												<input
													className='Lower'
													type="checkbox"
													onChange={this.ToggleIsiklikult}>
												</input>
											</td>
										</tr>}
									<tr>
										<td className='NormalField'>
											Pseudonüüm(id):
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Pseudonuumid'
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='Center' colSpan='2'>
											<button className='StandardButton' type='submit'>Registreeri kasutaja</button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
			</div>

		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	EndSubmitConnection(Response, EPost) {

		this.message.push([
			{
				value: 'Kasutaja registreeritud!',
				align: 'center'
			},
			{
				value: 'Parool saadetakse 10 sekundi jooksul e-posti aadressile: ' + EPost,
				align: 'center'
			}
		]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

		this.navigateToLogin = true;

	}

	HandleSubmit(event) {

		event.preventDefault();

		var Resident = '';
		var Isikukood = '';
		var Nimi = '';
		var JurIsikuRegNr = '';
		var FIERegNr = '';
		var BIC = '';

		if(event.target.Resident != null) {

			Resident = event.target.Resident.value;

		}

		if(event.target.Isikukood != null) {

			Isikukood = event.target.Isikukood.value;

		}

		if(event.target.Nimi != null) {

			Nimi = event.target.Nimi.value;

		}

		if(event.target.JurIsikuRegNr != null) {

			JurIsikuRegNr = event.target.JurIsikuRegNr.value;

		}

		if(event.target.FIERegNr != null) {

			FIERegNr = event.target.FIERegNr.value;

		}

		if(event.target.BIC != null) {

			BIC = event.target.BIC.value;

		}

		var errorMessage = [];

		var message = validateSubmitterFirstName(event.target.Eesnimed.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateSubmitterFamilyName(event.target.Perenimi.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateSubmitterType(this.state.Esitaja);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateCountryCode(Resident);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateIndividualCode(this.state.Esitaja, Resident, Isikukood);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateLegalName(this.state.Esitaja, Nimi);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateLegalCode(this.state.Esitaja, JurIsikuRegNr);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validatePersonalCode(this.state.Esitaja, FIERegNr);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateZIP(event.target.Postiindeks.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateEMail(event.target.EPost.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validatePhone(event.target.Telefon.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateIBAN(event.target.IBAN.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateBIC(this.state.BIC, BIC);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateAuthorFirstName(event.target.AutoriEesnimed.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = validateAuthorFamilyName(event.target.AutoriPerenimi.value);;

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var data =
				'{"author_first_name": "' + event.target.AutoriEesnimed.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"author_family_name": "' + event.target.AutoriPerenimi.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_role_cd": "' + this.Isiklikult + '",'
				+ '"applicant_first_name": "' + event.target.Eesnimed.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_family_name": "' + event.target.Perenimi.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"pseudonym": "' + event.target.Pseudonuumid.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"resident_country_cd": "' + Resident + '",'
				+ '"personal_cd": "' + Isikukood.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"legal_name": "' + Nimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"legal_cd": "' + JurIsikuRegNr.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"individual_cd": "' + FIERegNr.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"address_line2": "' + event.target.Aadress.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"address_line1": "' + event.target.Linn.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"zip_cd": "' + event.target.Postiindeks.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"email": "' + event.target.EPost.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"phone1": "' + event.target.Telefon.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"iban": "' + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"bic": "' + BIC.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

			var URL = this.APIURL + 'parties';

			var config = {

				headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
				timeout: 30000,

			};

			axios.post(URL, data, config).then((response) => this.EndSubmitConnection(response, event.target.elements.EPost.value)).catch(this.catchError);

		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	setShowMessage(showMessage) {

		if(this.navigateToLogin) {

      let url = this.props.router.url({ name: 'Login' });

      this.props.router.navigate({ url });

		}

		else {

			this.setState({showMessage: showMessage});

		}

	}

	ToggleBIC(event) {

		if(event.target.value.length >= 10 && event.target.value.substring(0, 2).toLowerCase() !== 'ee') {

			this.setState({BIC: true});

		}

		else {

			this.setState({BIC: false});

		}

	}

	ToggleEsitaja(event) {

		this.setState({Esitaja: event.target.value});

		if(event.target.value === 'jur_isik') {

			this.Isiklikult = '';

		}

	}

	ToggleFocusAuthor() {

		if(!this.state.FocusAuthor) {

			this.setState({FocusAuthor: true});

		}

	}

	ToggleIsiklikult() {

		if(this.Isiklikult === 'I') {

			this.Isiklikult = '';

		}

		else {

			this.Isiklikult = 'I';

		}

	}

	UpdateAutoriEesnimed(event) {

		if(!this.state.FocusAuthor) {

			this.setState({AutoriEesnimed: event.target.value});

		}

	}

	UpdateAutoriPerenimi(event) {

		if(!this.state.FocusAuthor) {

			this.setState({AutoriPerenimi: event.target.value});

		}

	}

}